import add from 'date-fns/add';
import format from 'date-fns/format';
import sub from 'date-fns/sub';
import { useQuery } from 'react-query';

import reportService from 'api/services/report/reportService';
import { ChartData, ChartDateRange, ChartTypes, Comparison } from 'types/chart';
import { CustomerId } from 'types/user';

const useChartData = (type: ChartTypes, selection: ChartDateRange) => {
  const { data, isLoading, isError, isSuccess } = useQuery<ChartData | undefined>(
    [type, selection.start, selection.end],
    async () => {
      return await chartQuery();
    },
    { staleTime: 60000, retry: false, refetchOnWindowFocus: false },
  );

  const chartQuery = async () => {
    const min = format(sub(new Date(), { days: selection.start }), 'yyyy-MM-dd');
    const max = format(sub(new Date(), { days: selection.end }), 'yyyy-MM-dd');

    return reportService.GetChartByName(type, min + 'T00:00:00.000Z', max + 'T23:59:59.000Z');
  };

  return { data, isLoading, isError, isSuccess };
};

const useComparisonChartData = (type: ChartTypes, selection: ChartDateRange, customerId: CustomerId) => {
  let start: Date = new Date();
  let end: Date = new Date();

  if (selection.comparison != null) {
    if (selection.comparison == Comparison.LAST_MONTH) {
      start = sub(new Date(), { days: selection.start, months: 1 });
      end = sub(new Date(), { days: selection.end, months: 1 });
    }
    if (selection.comparison == Comparison.LAST_YEAR) {
      start = sub(new Date(), { days: selection.start, years: 1 });
      end = sub(new Date(), { days: selection.end, years: 1 });
    }
    if (selection.comparison == Comparison.CUSTOM) {
      start = sub(new Date(), { days: selection.compareStart });
      end = sub(new Date(), { days: selection.compareEnd });
    }
  }

  const comparisonQuery = async () => {
    const min = format(start, 'yyyy-MM-dd');
    const max = format(end, 'yyyy-MM-dd');

    return reportService.GetChartByName(type, min + 'T00:00:00.000Z', max + 'T23:59:59.000Z');
  };

  const { data, isLoading, isError, isSuccess } = useQuery<ChartData | undefined>(
    [
      type,
      selection.start,
      selection.end,
      customerId,
      selection.comparison,
      selection.compareStart,
      selection.compareEnd,
    ],
    async () => {
      if (selection.comparison != null) {
        const data: ChartData = await comparisonQuery();
        if (data.dataPoints.length > 0) {
          return data;
        } else return Promise.reject('not enough data in result');
      } else return Promise.resolve(undefined);
    },
    { staleTime: 60000, retry: false, refetchOnWindowFocus: false },
  );

  return { data, isLoading, isError, isSuccess };
};

export { useComparisonChartData };
export default useChartData;
